import { PlusOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import { Button, Divider, Form, Typography } from 'antd';
import { navigate } from 'gatsby';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QualificationTemplate from '../components/form/qualifications';
import DefaultLayout from '../components/layouts/default.layout';
import { Navigation } from '../components/navigation';
import { updateQualifications } from '../state/actions';
import { IState } from '../state/types';

const { useState } = React;

const { Title, Paragraph, Text } = Typography;

const QualificationPage: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const qualifications = useSelector(
    (state: IState) => state.qualifications || []
  );
  const [showFillInAllFields, setshowFillInAllFields] = useState<boolean>(
    false
  );

  const handleNext = () => {
    form.submit();
    form
      .validateFields()
      .then(values => {
        setshowFillInAllFields(false);
        const formattedData = valuesToData(values.qualifications);
        dispatch(updateQualifications(formattedData));
        navigate(`/complete${window.location.search}`);
      })
      .catch(err => {
        setshowFillInAllFields(true);

        Sentry.captureException(
          'Failure when reading qualifications (rb)',
          context => {
            context.setLevel(Severity.Fatal);
            context.setExtras({
              error: err,
              error_fields: err?.errorFields[0],
              error_name: err.name,
              error_message: err.message,
              stack_trace: err.stack
            });
            return context;
          }
        );
      });
  };

  // const valuesToData = (values: any): Record<string, unknown>[] =>
  const valuesToData = (values: any) =>
    values.map((exp: Record<string, unknown>) => exp.name);

  const initialValues = () => qualifications.map(str => ({ name: str }));

  return (
    <DefaultLayout>
      <div style={s.header}>
        <Title level={2}>Certificates or Licenses</Title>
        <Paragraph>
          Almost done! Do you have any qualifications, certifications, or
          licenses (e.g. driving license) that you want to add?
        </Paragraph>
      </div>

      <Form
        form={form}
        initialValues={{ qualifications: initialValues() }}
        layout="vertical">
        <Form.List name="qualifications">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, i) => (
                <Fragment key={i}>
                  <QualificationTemplate
                    field={field}
                    remove={() => remove(field.name)}
                  />

                  <Divider />
                </Fragment>
              ))}

              <Button type="dashed" block onClick={add}>
                <PlusOutlined /> Add
              </Button>
            </>
          )}
        </Form.List>
      </Form>

      <div style={s.header}>
        <Navigation
          step={5}
          goBackHandler={() => navigate(`/education${window.location.search}`)}
          goNextHandler={handleNext}
        />
      </div>
    </DefaultLayout>
  );
};

export default QualificationPage;

const s: Stylesheet = {
  header: {
    textAlign: 'center',
    marginTop: 50
  }
};
