import React, { Fragment } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { useViewport } from '../../common/hooks';

interface IProps {
  field: FormListFieldData;
  remove: () => void;
}

const QualificationTemplate: React.FC<IProps> = ({ field, remove }) => {
  const { isMobile } = useViewport();

  const requiredRule = (validationMessage: string) => [
    { required: true, message: validationMessage, whitespace: true }
  ];

  return (
    <Fragment key={field.key}>
      <Row align="bottom" gutter={[16, 0]}>
        <Col span={24} lg={22}>
          <Form.Item
            style={{ marginBottom: isMobile ? 10 : 0 }}
            label="Certificate or License Name"
            name={[field.name, 'name']}
            rules={requiredRule('Please enter a certificate or license name')}>
            <Input placeholder="Example: Class 3A license" />
          </Form.Item>
        </Col>

        <Col span={24} lg={2}>
          <Button
            block={isMobile ? true : false}
            type="dashed"
            danger
            onClick={remove}>
            Remove
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default QualificationTemplate;
